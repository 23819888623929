@import url(~/node_modules/@fortawesome/fontawesome-free/css/all.css);
@import url(~/node_modules/halfmoon/css/halfmoon.css);

html {
    font-size: 62%;
}

body {
    font-family: "IBM Plex Mono", monospace !important;
}

.font-size-100 {
    font-size: 10rem;
}
